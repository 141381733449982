import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/xoxo/Projects/blog/put-money-values/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBanner = makeShortcode("AuthorBanner");
const FeedSearch = makeShortcode("FeedSearch");
const Tags = makeShortcode("Tags");
const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBanner mdxType="AuthorBanner" />
    <p>{`On this web-site you will find a list of companies that support freedom of speech and `}<a parentName="p" {...{
        "href": "https://www.conservapedia.com/Conservative_values"
      }}>{`conservative values`}</a>{`.
You will also find companies that contribute to the tech tyranny and work hard to take our freedoms away.`}</p>
    <p>{`Suggestions? Questions? `}<br />{`
`}<a parentName="p" {...{
        "href": "https://t.me/firgunwin/"
      }}>{`Please consider joining our Telegram group`}</a></p>
    <p>{`Nov 3rd 2020 was the day when fair elections have ceased to exist.
Dec 11th 2020 was the day when fair courts have ceased to exist.
Jan 6th 2021 was the day when the House has become a uni-party.
May 20th 2021 was the day when imprisoning US citizens for political reasons became the norm.`}</p>
    <hr></hr>
    <FeedSearch mdxType="FeedSearch" />
    <Tags mdxType="Tags" />
    <FeedItems mdxType="FeedItems" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      